@import "styles/variables.scss";

.root {
  display: none;

  @media screen and (max-width: $breakpoint-md) {
    position: relative;
    display: block;
    width: 114.2%;
    min-height: 62.8px;
    padding: 0 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1.43;
    color: var(--color-black);
    background-color: var(--color-alpha);
    background-image: url("../../../../public/img/chevron-down.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 20px);
    background-position-y: calc(50% - 1px);
    border-color: var(--color-golf);
    border-radius: 0;
    outline: none;
    transform: scale(0.875);
    transform-origin: left;
    appearance: none;
  }
}
