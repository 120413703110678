@import "styles/mixins.scss";
@import "styles/variables.scss";

.logo {
  min-width: 120px;
  max-width: 240px;

  @media screen and (max-width: $breakpoint-md) {
    min-width: 80px;
    max-width: 140px;
  }
}

.link {
  display: block;
}

.image {
  @include fluid-image;
  max-height: 120px;

  @media screen and (max-width: $breakpoint-md) {
    max-height: 65px;
  }
}

.in-footer {
  max-height: 80px;

  @media screen and (max-width: $breakpoint-md) {
    max-height: 55px;
  }
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.plug {
  color: var(--color-bravo);
  text-decoration: none;

  h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 18px;
    }
  }
}

.name {
  margin-bottom: 6px;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 0;
  }
}
