@import "styles/variables.scss";

.root {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 35px 20px;
  animation: smooth-appearance 1.8s;

  @media screen and (max-width: $breakpoint-md) {
    gap: 20px;
  }
}

@keyframes smooth-appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
