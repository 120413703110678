@import "styles/variables.scss";
@import "styles/mixins.scss";

.footer {
  padding-top: 90px;
}

.copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}

.text {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-bravo);
  letter-spacing: 0.5px;

  a {
    color: var(--color-bravo);
    text-decoration: none;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo {
  margin-bottom: 17px;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 27px;
}

.item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
}

.contact {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: var(--color-charlie);
  text-decoration: none;
  transition: 0.2s opacity;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-right: 0;
  }
}
