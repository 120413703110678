@import "styles/variables";

.root {
  &[aria-label][data-tooltip-position][data-tooltip-position^="down"] {
    &::before {
      display: none;
    }
  }

  &[aria-label][data-tooltip-position] {
    &::after {
      padding: 6px 5px;
      padding-top: 7px;
      font-family: var(--font-family-museo_sans_cyrl);
      font-style: normal;
      font-weight: 300;
      font-stretch: normal;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 100%;
    }
  }

  // @media screen and (max-width: $breakpoint-md) {
  //   &[aria-label][data-tooltip-position] {
  //     &::after {
  //       display: none;
  //     }

  //     &::before {
  //       display: none;
  //     }
  //   }

  //   &:global(.is-touch) {
  //     &[aria-label][data-tooltip-position] {
  //       &::after {
  //         display: inline-block;
  //         white-space: normal;
  //         line-height: 12px;
  //         margin-top: 0;
  //         opacity: 1;
  //       }
  //     }
  //   }
  // }
}

.light {
  &[aria-label][data-tooltip-position] {
    &::after {
      padding: 10px 12px;
      background-color: var(--color-white);
      color: var(--color-black);
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
      white-space: pre-line;
      text-align: start;
      text-transform: none;
    }
  }
}

.hidden {
  &[aria-label][data-tooltip-position] {
    &::after {
      display: none !important;
    }
  }
}
