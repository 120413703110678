@import "styles/variables.scss";

.root {
  position: relative;
  z-index: 1;
  grid-column: span 2;
  width: 100%;
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    grid-column: span 6;
  }
}

.thumbnail {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 66.6667%;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: var(--color-india);

  &:hover {
    .image {
      opacity: 0.85;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 13px;
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 2;
  display: block;
  min-width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.75s opacity;
  transform: translateX(-50%);

  object-fit: cover;
  will-change: opacity;
}

.ready {
  opacity: 1;
}

.to-gallery {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: block;
}

.title {
  display: block;
  margin-bottom: 7px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  color: var(--color-bravo);
  text-align: center;
  text-align: center;
  text-decoration: none;
  letter-spacing: normal;
  overflow-wrap: break-word;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 300;
  }
}

.description {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-charlie);
  text-align: center;
  letter-spacing: normal;
}

.date {
  display: block;
  width: 100%;
  text-align: center;
  opacity: 1;
}

.status-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  width: 30px;
  height: 30px;
}

.locked {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

  & svg {
    position: relative;
    bottom: 1px;
  }
}

.picture-icon {
  margin-right: 5px;
}
