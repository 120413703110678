@import "@app/styles/colors";
@import "@app/styles/variables.scss";

.button {
  position: relative;
  padding: 18px 34px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50em;
  transition: 0.2s box-shadow;
}

.primary {
  color: var(--color-white-text);
  background-color: var(--color-bravo);

  &:not(.disabled):hover {
    box-shadow: 0 0 0 2px var(--color-bravo);
  }
}

.secondary {
  color: var(--color-bravo);
  background-color: var(--color-india);

  &:not(.disabled):hover {
    box-shadow: 0 0 0 2px var(--color-india);
  }
}

.inverted {
  color: var(--color-bravo);
  background-color: var(--color-alpha);
  border: 2px solid transparent;

  &:not(.disabled):hover {
    box-shadow: 0 0 0 2px var(--color-alpha);
  }
}

.light {
  transition: all 0.3s ease-in-out;

  &:not(.disabled):hover {
    cursor: pointer;
  }
}

.inner {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  transform: translate(-50%, -50%);

  svg {
    width: 20px;
    height: 20px;
    stroke: var(--color-white);
  }
}

.loading {
  .inner {
    visibility: hidden;
    opacity: 0;
  }

  .spinner {
    visibility: visible;
    opacity: 1;
  }
}

.disabled {
  background: #eeeeee;
  color: #929292;
  cursor: default;
}
