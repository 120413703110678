.qr-code-body {
  margin-top: 50px;
}

.back {
  position: absolute;
  top: 33px;
  left: 30px;
}

.qr-code {
  margin-top: 28px;
  padding: 15px;
  background-color: var(--common-color-white);
}

.copy-contact {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  color: var(--color-black);
}
