@import "styles/variables";

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 30%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
}

.open {
  visibility: visible;
  opacity: 1;
  overflow-y: auto;
  pointer-events: auto;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 450px;
  padding: 50px 30px;
  background-color: var(--color-alpha);
  transform: translate(-50%, -50%);
  overflow: auto;

  @media screen and (max-width: $breakpoint-lg) {
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    padding: 25px;
  }
}

.wrapper {
  flex-basis: 100%;
}

.cross {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
  padding: 10px;
  display: flex;

  @media screen and (min-width: $breakpoint-lg) {
    will-change: transform;
    transition: 0.2s transform;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.cross-mobile-hide {
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.go-back-button {
  position: absolute;
  top: 27px;
  left: 70px;
  z-index: 2;

  @media screen and (max-width: $breakpoint-lg) {
    visibility: hidden;
  }
}

.cross-secondary {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

.mobile-type {
  @media screen and (max-width: $breakpoint-lg) {
    top: auto;
    left: 0;
    bottom: 0;
    height: 100vh;
    max-height: 100vh;
    transform: none;
    padding-top: 40px;
    padding-bottom: 40px;

    .modal {
      height: auto;
      max-width: 100%;
      min-height: 185px;
      padding: 25px;
      top: auto;
      bottom: 0;
      transform: translate(-50%, 0);
    }
  }
}

.back {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
  }
}

.line-close {
  display: none;
  position: absolute;
  width: 40px;
  height: 16px;
  left: 50%;
  top: 3px;
  margin-left: -20px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 100%;
    background-color: var(--color-super-silver);
  }

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}
