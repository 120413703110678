@import "styles/mixins.scss";
@import "styles/variables.scss";

.header {
  position: relative;
  padding-top: 25px;
  margin-bottom: 53px;

  &::after {
    position: absolute;
    bottom: 0;
    left: -65px;
    display: block;
    width: calc(100% + 130px);
    height: 1px;
    content: "";
    background-color: var(--color-india);
  }

  @media screen and (max-width: $breakpoint-md) {
    position: relative;
    padding-top: 20px;
    margin-bottom: 30px;
    border-bottom: 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: -25px;
      display: block;
      width: calc(100% + 50px);
      height: 1px;
      content: "";
      background-color: var(--color-india);
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 29px;

  img {
    margin-right: auto;
    margin-bottom: 2px;
    margin-left: auto;
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 19px;
  }
}

.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.info {
  display: flex;
  flex-wrap: wrap;
  width: auto;
}

.avatar {
  width: 100%;
  margin-bottom: 19px;

  text-align: center;
}

.image {
  @include fluid-image;

  max-width: 120px;
  margin: 0 auto 31px auto;

  border-radius: 50%;

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 21px;
  }
}

.name {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;

  color: var(--color-bravo);

  text-align: center;
  letter-spacing: normal;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 28px;
}

.contact {
  margin: 0 12px;

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;

  color: var(--color-charlie);
  text-align: center;
  text-decoration: none;
  letter-spacing: normal;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    margin: 0 0 3px 0;
    line-height: 1.5;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contact {
  transition: 0.2s opacity;

  &:hover {
    opacity: 0.7;
  }
  &:last-child {
    margin-right: 0;
  }
}
