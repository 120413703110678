@import "styles/variables.scss";

.go-back {
  display: flex;
  align-items: center;
  color: var(--color-bravo);
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.go-back-text {
  margin-left: 15px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color--black);
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 23px;
}

.mobile-hide {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.compact {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.compact-sm {
  @media screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}
