@import "@app/styles/variables.scss";

.modal {
  max-width: 500px;
  min-height: 450px;
  max-height: 98vh;
  padding: 80px 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: $breakpoint-md) {
    min-height: unset;
    max-height: 100vh;
    padding: 50px 30px;
  }
}

.inside-wrapper {
  width: 100%;
}

.title {
  font-family: var(--heading-font-family);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: var(--heading-font-weight);
  line-height: 111.111%;
  color: var(--color-black);
}
