@import "styles/variables.scss";

.list {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 52px;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.option {
  display: inline-block;
  margin-right: 25px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.85;
  color: var(--color-black);
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.active,
.default {
  color: var(--color-charlie);
}
