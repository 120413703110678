.social-networks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  .social-network {
    display: flex;
    margin-right: 23px;
    list-style: none;
    transition: 0.2s opacity;

    &:hover {
      opacity: 0.7;
    }

    &:last-child {
      margin-right: 0;
    }

    .social-link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}
