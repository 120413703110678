@import "@app/styles/variables.scss";

.desktop-wrapper {
  width: 100%;
  display: block;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.mobile-wrapper {
  width: 100%;
  display: none;

  @media screen and (max-width: $breakpoint-md) {
    // min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  font-family: var(--heading-font-family);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: var(--heading-font-weight);
  line-height: 111.111%;
  color: var(--color-black);
}

.direction {
  padding-top: 14px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 218.182%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-black);
}

.phone {
  // margin-top: 40px;
  margin-bottom: 6px;
  text-decoration: none;
  color: var(--color-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

.content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.visit-card-social {
  width: 100%;
  max-width: 300px;
  padding: 15px 0;
  background-color: transparent;
  border-radius: 36px;
  border: 1.5px solid var(--color-black);

  span {
    color: var(--color-black);
  }
}

.social-media {
  margin-top: 0;

  ul {
    gap: 20px;
  }
}

.social-list {
  margin-top: 32px;
  margin-bottom: 0 !important;

  li {
    margin-right: 0 !important;
  }

  svg {
    width: 32px;
    height: 32px;
  }
}

.site {
  display: block;
  margin-top: 6px;
  text-decoration: none;
  color: var(--color-black);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 218.182%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.copy-contact {
  width: 100%;
  max-width: 300px;
  padding: 17px 0;
  color: var(--color-white);
  background-color: var(--color-black);
}
