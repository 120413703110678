@import "styles/variables.scss";

.container {
  width: auto;
  max-width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: $breakpoint-md) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.full-width {
  width: 100%;
}
