.root {
  width: 22px;
  height: 22px;
  overflow: hidden;
}

.svg {
  width: 22px;
  height: 22px;
  stroke: var(--color-bravo);
  stroke-width: 3;
  transform-origin: center;
  animation: rotate 1.6s linear infinite;
}

.circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
