@import "@app/styles/variables.scss";

.visit-card {
  padding: 10px 15px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 12;
  border-radius: 8px;
  background-color: var(--common-color-white);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.09);
  color: var(--common-color-black);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  letter-spacing: 0;

  @media screen and (max-width: $breakpoint-md) {
    right: 20px;
    bottom: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .photo-inside-text {
      @media screen and (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }
}

.photo-inside-button {
  @media screen and (max-width: $breakpoint-md) {
    max-width: 42px;
    padding: 9px;
    border-radius: 50%;
  }
}
